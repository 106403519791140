import { defineStore } from 'pinia';
import { useDomainStore } from './domain';

export const useAppStore = defineStore('app', {
  state: () => ({
    currentTab: window.localStorage.getItem('currentTab') || 'lead',
    currentSubTab: window.localStorage.getItem('currentSubTab'),
    appPaginationSize: window.localStorage.getItem('appPaginationSize') || 50,
    appNextCursor: window.localStorage.getItem('appNextCursor'),
    closedCurrentTab: 'leadRejected',
    currentProduct: window.localStorage.getItem('currentProduct'),
    appsLoaded: false,
    listSettings: window.localStorage.getItem('listSettings'),
    onlineLender: false,
    allowExport: false,
    fields: null,
    products: [],
  }),

  actions: {
    set(setVar , val) {
      this[setVar] = val;
      if (['currentTab', 'currentSubTab', 'currentProduct'].includes(setVar)) window.localStorage.setItem(setVar, val);
    },
    refreshApp(product, data) {
      this.apps[product] = data;
    },
    clearApp () {
      this.currentTab = window.localStorage.getItem('currentTab') || 'lead';
      this.currentSubTab = window.localStorage.getItem('currentSubTab');
      this.appPaginationSize = window.localStorage.getItem('appPaginationSize') || 50;
      this.appNextCursor = window.localStorage.getItem('appNextCursor');
      this.closedCurrentTab = 'leadRejected';
      this.currentProduct = window.localStorage.getItem('currentProduct');
      this.appsLoaded = false;
      this.listSettings = window.localStorage.getItem('listSettings');
      this.onlineLender = false;
      this.allowExport = false;
      this.fields = null;
      this.products = [];
    },
    toggleField (field, val) {
      this.fields[this.currentProduct][this.currentTab][field].display = val;
    },
    toggleAllField (show) {
      Object.keys(this.fields[this.currentProduct][this.currentTab]).forEach((field) => {
        this.fields[this.currentProduct][this.currentTab][field].display = show;
      });
    },
    setToggleFieldTouched () {
      this.toggleFieldTouched = true;
    },
    toggleUserHiddenFields () {
      if (!this.listSettings) return;
      const listSettings = JSON.parse(this.listSettings);
      try {
        Object.keys(listSettings).forEach((stage) => {
          const stageListSettings = listSettings[stage];
          if (!stageListSettings || !stageListSettings.hideFields) return;
          const hiddenFields = stageListSettings.hideFields;
          hiddenFields.forEach((field) => {
            if (this.fields[this.currentProduct][stage][field])
              this.fields[this.currentProduct][stage][field].display = false;
          });
        })
      } catch (error) {
        console.log(error)
      }
    },
    updatePartnerComment (id, comment) {
      if (this.currentTab === 'closed') {
        const appIndex = this.apps[this.currentProduct][this.currentTab][this.closedCurrentTab].findIndex(a => a._id === id);
        if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][this.closedCurrentTab][appIndex].partnerComment = comment;
      } else {
        const appIndex = this.apps[this.currentProduct][this.currentTab].findIndex(a => a._id === id);
        if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][appIndex].partnerComment = comment;
      }
    },
    updateAppointment (id, appointment) {
      const appIndex = this.apps[this.currentProduct][this.currentTab].findIndex(a => a._id === id);
      
      if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][appIndex].appointmentDateTime = appointment;
    },
    updateAppointmentAddress (id, appointmentAddress) {
      const appIndex = this.apps[this.currentProduct][this.currentTab].findIndex(a => a._id === id);
  
      if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][appIndex].appointmentAddress = appointmentAddress;
    },
    updateAppointmentDocuments (id, appointmentDocuments) {
      const appIndex = this.apps[this.currentProduct][this.currentTab].findIndex(a => a._id === id);
      if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][appIndex].appointmentDocuments = appointmentDocuments;
    },
    flagAppointmentMissed (id, appointmentMissed) {
      const appIndex = this.apps[this.currentProduct][this.currentTab].findIndex(a => a._id === id);
      if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][appIndex].appointmentMissed = appointmentMissed;
    },
    updateUploadDocuments (id, uploadDocuments) {
      const appIndex = this.apps[this.currentProduct][this.currentTab].findIndex(a => a._id === id);
      if (appIndex !== -1) this.apps[this.currentProduct][this.currentTab][appIndex].uploadDocuments = uploadDocuments;
    },
    updateAppsLoadedFlag (flag) {
      this.appsLoaded = flag;
    },
    setListSettings (settings) {
      if (!settings) return;
      this.listSettings = JSON.stringify(settings);
      window.localStorage.setItem('listSettings', JSON.stringify(settings));
    },
    setTabHiddenFieldsSettings (stage, hideFields) {
      let update = this.listSettings;
  
      update = (!update) ? {} : JSON.parse(update);
  
      if (!update[stage]) update[stage] = {};
      update[stage].hideFields = hideFields;
      this.listSettings = JSON.stringify(update);
      window.localStorage.setItem('listSettings', JSON.stringify(update));
    },
    setOnlineLenderFlag (flag) {
      this.onlineLender = flag;
    },
    setAllowExportFlag (flag) {
      this.allowExport = flag;
    },
    setUserViewSettings (data) {
      this.userViewSettings = data;
    },
    setAppStore (setVar, val) {
      this.set(setVar, val);
    },
    clearAppStore () {
      this.clearApp();
    },
    showAllFields () {
      this.toggleAllField(true);
    },
    hideAllFields () {
      this.toggleAllField(false);
    },
    setUserHiddenFields () {
      this.toggleUserHiddenFields();
    },
    setAppsLoaded () {
      this.updateAppsLoadedFlag(true);
    },
    setAppsNotLoaded () {
      this.updateAppsLoadedFlag(false);
    },
    currentProductSettings () {
      const domainStore = useDomainStore();
      const products = domainStore.products();
      const product = products.find(({ value }) => this.currentProduct === value) ?? {};
      return product.settings ?? {};
    }
  }
});
