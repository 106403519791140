import { defineStore } from 'pinia';

export const useNuxtStore = defineStore('nuxt', {
  state: () => ({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    locale: window.localStorage.getItem('locale') || null,
    language: (window.localStorage.getItem('language')) ? window.localStorage.getItem('language') : 'EN',
    }),

    actions: {
      reset () {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.isMobile = (window.innerWidth <= 480);
      },
      resetLocale () {
        this.locale = null;
      },
      setLocale (locale) {
        const defaultLanguage = { hk: 'CN', sg: 'EN', au: 'EN' };
        
        this.locale = locale;
        window.localStorage.setItem('locale', locale);
    
        this.language = defaultLanguage[locale] || 'EN';
        window.localStorage.setItem('language', defaultLanguage[locale] || 'EN');
      },
      setLang (lang) {
        this.language = lang;
        window.localStorage.setItem('language', lang);
      },
      resetDimensions () {
        this.reset();
      },
    }
});
